*.product-module-wrapper{
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100vh;
  overflow-wrap: normal;
  overflow-x: auto;
}

.product-detail-productpage {
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .product-detail-productpage {
    flex-direction: column;
  }
}

.product-images {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* .product-images img {
  max-width: 100%;
  border-radius: 5px;
  border:#007bff 1px solid;
} */

.product-info-productpage {
  flex: 2;
  padding: 0 20px;
  border-left: 1px solid #ccc;
}

.product-info h1 {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
  font-size: 24px;
  margin: 0;
}

.product-description-productpage {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
  margin-top: 10px;
  font-size: 16px;
}

.product-rating-productpage {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
  margin: 10px 0;
  font-size: 16px;
}

.product-size,
.product-quantity-productpage {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.product-size label,
.product-quantity label {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
  margin-right: 10px;
}

.product-size select,
.product-quantity input {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
  padding: 5px;
  font-size: 16px;
}

.product-price-productpage {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
  margin: 10px 0;
  font-size: 18px;
  font-weight: 600;
}

.add-to-cart-button {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-to-cart-button:hover {
  background-color: #0056b3;
}

@media screen and (max-width: 768px) {
  .product-detail {
    flex-direction: column;
  }

  .product-info {
    border-left: none;
    padding: 0;
  }
}

/* gpt img gallery preview */

.product-images {
  display: flex;
  flex-direction: column;
  box-shadow: #ccc 1px 1px 5px 1px;
}

.gallery-container {
  justify-content: center;
  position: relative;
  display: flex;
  align-items: center;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  cursor: pointer;
  user-select: none;
  background: rgba(255, 255, 255, 0.5);
  padding: 10px 15px;
  border-radius: 50%;
  transition: background 0.3s;
}

.left-arrow {
  left: 0;
}

.right-arrow {
  right: 0;
}

.left-arrow:hover,
.right-arrow:hover {
  background: rgba(255, 255, 255, 0.8);
}

.images-container {
  justify-content: center;
  display: flex;
  overflow: hidden;
}

.images-container img {
  max-width: 300px;
  max-height: 300px;
  transition: transform 0.3s;
}

.image-previews {
  display: flex;
  overflow: auto;
  margin-top: 10px;
  gap: 10px;
}

.image-previews .image-preview {
  cursor: pointer;
  max-width: 60px;
  max-height: 60px;
  min-width: 60px;
  border: 1px solid #ccc;
}

.image-previews img {
  max-width: 100%;
  max-height: 100%;
  min-width: 60px;
  min-height: 100%;
  display: block;
  cursor: pointer;
}

/* Highlight the selected preview */
.image-previews .selected {
  border: 2px solid #007bff;
}
