@media (max-width: 1300px) {}

@media (max-width: 1120px) {}

@media (max-width: 992px) {
  .hero_area {
    min-height: auto;
  }

  .custom_nav-container .navbar-nav {
    padding-top: 15px;
    align-items: center;
  }


  .custom_nav-container .navbar-nav .nav-item .nav-link {
    padding: 5px 25px;
    margin: 5px 0;
  }

  .slider_section .carousel-indicators {
    margin-top: 45px;
  }

  .client_section .carousel-control-prev {
    left: 15%;
  }

  .client_section .carousel-control-next {
    right: 15%;
  }

}

@media (max-width: 767px) {

  .slider_bg_box img {
    -o-object-position: top center;
    object-position: top center;
  }

  .arrival_section .arrival_bg_box img {
    -o-object-position: top right;
    object-position: top right;
  }

  .client_section .carousel-control-prev {
    left: 5%;
  }

  .client_section .carousel-control-next {
    right: 5%;
  }

  .slider_section .detail-box,
  .about_section .detail-box {
    margin-bottom: 45px;
  }

  .about_section .row {
    flex-direction: column-reverse;
  }

  .subscribe_section .box,
  .arrival_section .box {
    padding: 75px 25px;
  }

  .subscribe_section .subscribe_form form button {
    margin-top: 15px;
  }
}

@media (max-width: 576px) {
  .heading_container h2 {
    font-size: 2.2rem;
  }
}

@media (max-width: 480px) {
  .heading_container h2 {
    font-size: 2rem;
  }

  .carousel_btn_box {
    display: flex;
    justify-content: center;
    margin-top: 25px;
  }

  .client_section .carousel-control-prev,
  .client_section .carousel-control-next {
    position: unset;
    transform: none;
  }
}

@media (max-width: 420px) {}

@media (max-width: 376px) {
  .slider_section .detail-box h1 {
    font-size: 3rem;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}