.shop-page-wrapper {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  min-height: 100vh;
  max-width: 100vw;
  margin: 0 0 auto 0;
  padding: 0px;
  background-color: #f7f7f7;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.shop-header-top {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  background-color: #333;
  color: #fff;
}

.shop-header-top-left {
  display: flex;
  align-items: center;
}

.shop-header-top-right {
  margin: auto 5vw auto 5vw;
  display: flex;
  align-items: center;
}

    /* Add more specific styles for logo, search, cart, and user components */

    .shop-header-top-right-cart {
      margin: auto 1vw auto 1vw;
      display: flex;
      align-items: center;
    }
    .shop-header-top-right-user {
      margin: auto 1vw auto 1vw;
      display: flex;
      align-items: center;
    }

.shop-header-bottom {
  background-color: #444;
  padding: 10px 0;
}

.shop-header-bottom-category-list {
  height: 5vh;
  display: flex;
  list-style: none;
}

.shop-header-bottom-category-list-category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  padding: 0 20px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.shop-header-bottom-category-list-category a{
  color: #fff;
  text-decoration: none;
}

.shop-header-bottom-category-list-category a:focus{
  color: #b14242;
  text-decoration: none;
}

.shop-body {
  display: flex;
  padding: 20px 0;
  height: 100%;
}

.shop-body-product-info {
  width: 100%;
  margin: 0 0 0 4px;
}

.shop-body-product-list {
  width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* maybe change to left or right ? add filtering to either side?*/
  }
  
  .shop-body-product-list-product {
    width: calc(24% - 20px);
    min-width: calc(24% - 20px);
    margin: 2vh 2% 4vh 2%;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    padding: 0 0 0 0;
    border-radius: 5px;
  }

  .shop-body-product-list-product a{
    text-decoration: none;
    color: #333;
  }
  
  .shop-body-product-list-product:hover {
    transform: scale(1.02);
  }
  
  .shop-body-product-image img {
    border-radius: 5px 5px 0 0;
    max-width: 100%;
    height: auto;
    width: auto; /* Remove the 'width: 100%' and 'object-fit' */
  }
  
  .shop-body-product-name {
    font-weight: bold;
    margin: 10px 0;
    font-size: 18px;
  }
  
  .shop-body-product-detail {
    font-size: 14px;
    color: #777;
  }
  
  .shop-body-product-price {
    font-size: 18px;
    font-weight: bold;
  }
  
  .add-to-cart {
    background-color: #36a5ff;
    color: white;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-to-cart:hover {
    background-color: #e1334d;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .shop-body-product-list-product {
      min-width: calc(24% - 20px);
      width: calc(48% - 20px);
      margin: 0 2% 4vh 2%;
    }
  }
  /* @media (max-width: 480px) {
    .shop-body-product-list-product {
      width: 100%;
      margin: 0 2% 4vh 2%;
    }
  } */
