$white: #ffffff;
$black: #000000;
$primary1: #f7444e;
$primary2: #002c3e;
$textCol: #1f1f1f;

// import fonts -  font-family: 'Poppins', sans-serif and  font-family: 'Playfair Display', serif

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap');

@mixin main-font {
  font-family: 'Poppins', sans-serif;
}

@mixin second-font {
  font-family: 'Playfair Display', serif;
}

@mixin hero_btn($col1, $col2, $pad1, $pad2, $bRadius) {
  display: inline-block;
  padding: $pad1 $pad2;
  background-color: $col1;
  border: 1px solid $col1;
  color: $col2;
  border-radius: $bRadius;
  transition: all 0.3s;

  &:hover {
    background-color: transparent;
    color: $col1;
  }
}

@mixin upperBold {
  text-transform: uppercase;
  font-weight: bold;
}

body {
  @include main-font;
  color: #0c0c0c;
  background-color: #ffffff;
  overflow-x: hidden;
}

.layout_padding {
  padding: 90px 0;
}

.layout_padding2 {
  padding: 75px 0;
}

.layout_padding2-top {
  padding-top: 75px;
}

.layout_padding2-bottom {
  padding-bottom: 75px;
}

.layout_padding-top {
  padding-top: 90px;
}

.layout_padding-bottom {
  padding-bottom: 90px;
}

h1,
h2 {
  @include second-font();
}

.heading_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h2 {
    position: relative;
    margin-bottom: 0;
    font-size: 2.5rem;
    font-weight: bold;

    span {
      color: $primary1;
    }
  }

  p {
    margin-top: 10px;
    margin-bottom: 0;
  }

  &.heading_center {
    align-items: center;
    text-align: center;
  }
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a:hover,
a:focus {
  color: initial;
}

.btn,
.btn:focus {
  outline: none !important;
  box-shadow: none;
}

/*header section*/
.hero_area {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.sub_page {
  .hero_area {
    min-height: auto;
    box-shadow: 0 0 5px 0 rgba($color: #000000, $alpha: .25);
  }

}

.header_section {
  padding: 10px 0;

  .container-fluid {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.navbar-brand {
  @include second-font();

  span {
    font-weight: bold;
    font-size: 32px;
    color: $black;
  }
}

.custom_nav-container {
  padding: 0;

  .navbar-nav {
    margin-left: auto;

    .nav-item {
      .nav-link {
        padding: 5px 20px;
        color: $black;
        text-align: center;
        text-transform: uppercase;
        border-radius: 5px;
        transition: all 0.3s;

        svg {
          width: 17px;
          height: auto;
          fill: $black;
          margin-bottom: 2px;
        }
      }

      &:hover,
      &.active {
        .nav-link {
          color: $primary1;

          svg {
            fill: $primary1;
          }
        }
      }
    }
  }
}

.custom_nav-container .nav_search-btn {
  width: 35px;
  height: 35px;
  padding: 0;
  border: none;
  color: $black;

  &:hover {
    color: $primary1;
  }
}

.custom_nav-container .navbar-toggler {
  outline: none;
}

.custom_nav-container .navbar-toggler {
  padding: 0;
  width: 37px;
  height: 42px;
  transition: all 0.3s;

  span {
    display: block;
    width: 35px;
    height: 4px;
    background-color: $black;
    margin: 7px 0;
    transition: all 0.3s;
    position: relative;
    border-radius: 5px;
    transition: all 0.3s;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $black;
      top: -10px;
      border-radius: 5px;
      transition: all 0.3s;
    }

    &::after {
      top: 10px;
    }
  }

  &[aria-expanded="true"] {
    transform: rotate(360deg);

    span {
      transform: rotate(45deg);

      &::before,
      &::after {
        transform: rotate(90deg);
        top: 0;
      }
    }


  }


}

/*end header section*/

/* slider section */
.slider_section {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  padding: 90px 0 75px 0;

  .row {
    align-items: center;
  }

  #customCarousel1 {
    width: 100%;
    position: unset;
  }

  .detail-box {

    h1 {
      font-size: 3.5rem;
      font-weight: bold;
      margin-bottom: 15px;
      color: $primary2;

      span {
        color: $primary1;
      }
    }

    p {
      font-size: 14px;
    }

    a {
      @include hero_btn($primary1, $white, 10px, 45px, 0);
      margin-top: 10px;
    }

  }

  .img-box {
    img {
      width: 100%;
    }

  }


  .carousel-indicators {
    position: unset;
    margin: 0;
    margin-top: 45px;
    justify-content: flex-start;
    align-items: center;

    li {
      background-color: $white;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      opacity: 1;

      &.active {
        width: 20px;
        height: 20px;
        background-color: $primary1;
      }
    }
  }
}

.slider_bg_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top right;
  }
}

// end slider section

// product section
.product_section {

  .heading_container {
    margin-bottom: 20px;
  }

  .box {
    position: relative;
    margin-top: 25px;
    padding: 15px;
    background-color: #f7f8f9;
    transition: all .3s;
    overflow: hidden;

    .img-box {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 215px;

      img {
        max-width: 100%;
        max-height: 160px;
        transition: all .3s;
      }
    }

    .detail-box {
      text-align: center;
      display: flex;
      justify-content: space-between;

      h5 {
        font-size: 18px;
        margin-top: 10px;
      }

      h6 {
        margin-top: 10px;
        color: $primary2;
        font-weight: 600;
      }
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    &:hover {
      .option_container {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }
  }

  .option_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #cac9c7, $alpha: .75);
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    transition: all .2s;
    transform: translateY(100%);
  }

  .options {
    display: flex;
    flex-direction: column;
    align-items: center;


    a {
      display: inline-block;
      padding: 8px 15px;
      border-radius: 30px;
      width: 165px;
      text-align: center;
      transition: all .3s;
      margin: 5px 0;
    }

    .option1 {
      background-color: $primary1;
      border: 1px solid $primary1;
      color: $white;

      &:hover {
        background-color: transparent;
        color: $primary1;
      }
    }

    .option2 {
      background-color: $black;
      border: 1px solid $black;
      color: $white;

      &:hover {
        background-color: transparent;
        color: $black;
      }
    }

  }

  .btn-box {
    display: flex;
    justify-content: center;
    margin-top: 45px;

    a {
      @include hero_btn($primary1, $white, 10px, 40px, 0);
    }
  }
}

// end product section

// arrival section

.arrival_section {
  .heading_container {
    color: $primary2;
    margin-bottom: 10px;
  }

  .box {
    padding: 120px 45px;
    position: relative;
  }

  .arrival_bg_box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top left;
    }
  }

  a {
    @include hero_btn($primary1, $white, 10px, 45px, 0);
  }
}

// end arrival section

// why section

.why_section {


  .box {
    text-align: center;
    margin-top: 45px;
    background-color: $primary2;
    padding: 25px;
    border-radius: 5px;
    color: $white;

    .img-box {
      margin-bottom: 15px;

      svg {
        width: 55px;
        height: auto;
        fill: $white;
      }
    }
  }
}

//end why section

// subscribe section

.subscribe_section {
  text-align: center;

  .box {
    background-color: #d4ced0;
    padding: 75px 45px;
  }

  .subscribe_form {
    .heading_container {
      justify-content: center;
      color: $primary2;

      h2 {
        padding: 0 25px;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;

      input {
        width: 100%;
        height: 50px;
        border: none;
        border-radius: 50px;
        outline: none;
        padding: 0 25px;
        background-color: $white;
        margin-top: 15px;
        text-align: center;

        &::placeholder {
          color: #171620;
        }
      }

      button {
        @include hero_btn($primary1, $white, 10px, 45px, 35px);
        text-transform: uppercase;
        margin-top: 35px;
        font-weight: 600;
        width: 100%;
        max-width: 285px;
      }
    }
  }
}

// end subscribe section

// client section

.client_section {


  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 55px;

    .img_container {
      width: 275px;
      height: 275px;
      border-radius: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-color: #3c3c3c;

      .img-box {
        width: 215px;
        height: 215px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white;
        margin-right: -1px;

        .img_box-inner {
          width: 150px;
          height: 150px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          margin-right: 10px;

          img {
            width: 100%;
          }
        }
      }
    }

    .detail-box {
      margin-top: 25px;

      h5 {
        font-size: 20px;
        font-weight: 600;
      }

      h6 {
        font-size: 15px;
        color: #999998;
      }
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    top: 43%;
    transform: translateY(-50%);
    opacity: 1;
    width: auto;
    margin: 0 5px;
    width: 50px;
    height: 50px;
    opacity: 1;
    color: $white;
    background-color: $primary1;
    border-radius: 100%;

    &:hover {
      background-color: #212121;
    }
  }

  .carousel-control-prev {
    left: 25%;
  }

  .carousel-control-next {
    right: 25%;
  }

}

// end client section

/* footer section*/

.footer_section {
  background-color: $primary2;
  color: $white;
  padding-top: 70px;
  text-align: center;

  h4 {
    font-size: 28px;
  }

  h4,
  .footer-logo {
    font-weight: 600;
    margin-bottom: 20px;
    @include second-font();
  }

  .footer-col {
    margin-bottom: 30px;
  }

  .footer_contact {

    .contact_link_box {
      display: flex;
      flex-direction: column;

      a {
        margin: 5px 0;
        color: $white;

        i {
          margin-right: 5px;
        }

        &:hover {
          color: $primary1;
        }
      }
    }
  }

  .footer-logo {
    display: block;
    font-weight: bold;
    font-size: 32px;
    color: $white;
  }

  .footer_social {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary2;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background-color: $white;
      border-radius: 100%;
      margin: 0 2.5px;
      font-size: 18px;

      &:hover {
        color: $primary1;
      }
    }
  }




  .map_container {
    width: 100%;
    height: 175px;
    overflow: hidden;
    display: flex;
    align-items: stretch;

    .map {
      height: 100%;
      flex: 1;

      #googleMap {
        height: 100%;
      }
    }
  }


  .footer-info {
    text-align: center;
    margin-top: 25px;

    p {
      color: $white;
      margin: 0;
      padding: 25px 0;
      border-top: 1px solid #efefef;

      a {
        color: inherit;
      }
    }
  }
}

// end footer section